// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-chirurgie-tsx": () => import("./../../../src/pages/chirurgie.tsx" /* webpackChunkName: "component---src-pages-chirurgie-tsx" */),
  "component---src-pages-comorbidites-tsx": () => import("./../../../src/pages/comorbidites.tsx" /* webpackChunkName: "component---src-pages-comorbidites-tsx" */),
  "component---src-pages-contraception-tsx": () => import("./../../../src/pages/contraception.tsx" /* webpackChunkName: "component---src-pages-contraception-tsx" */),
  "component---src-pages-generalities-anneau-tsx": () => import("./../../../src/pages/generalities_anneau.tsx" /* webpackChunkName: "component---src-pages-generalities-anneau-tsx" */),
  "component---src-pages-generalities-bypass-tsx": () => import("./../../../src/pages/generalities_bypass.tsx" /* webpackChunkName: "component---src-pages-generalities-bypass-tsx" */),
  "component---src-pages-generalities-derivation-tsx": () => import("./../../../src/pages/generalities_derivation.tsx" /* webpackChunkName: "component---src-pages-generalities-derivation-tsx" */),
  "component---src-pages-generalities-sleeve-tsx": () => import("./../../../src/pages/generalities_sleeve.tsx" /* webpackChunkName: "component---src-pages-generalities-sleeve-tsx" */),
  "component---src-pages-generalities-tsx": () => import("./../../../src/pages/generalities.tsx" /* webpackChunkName: "component---src-pages-generalities-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reintervention-tsx": () => import("./../../../src/pages/reintervention.tsx" /* webpackChunkName: "component---src-pages-reintervention-tsx" */),
  "component---src-pages-suivi-anneau-tsx": () => import("./../../../src/pages/suivi_anneau.tsx" /* webpackChunkName: "component---src-pages-suivi-anneau-tsx" */),
  "component---src-pages-suivi-bypass-tsx": () => import("./../../../src/pages/suivi_bypass.tsx" /* webpackChunkName: "component---src-pages-suivi-bypass-tsx" */),
  "component---src-pages-suivi-derivation-tsx": () => import("./../../../src/pages/suivi_derivation.tsx" /* webpackChunkName: "component---src-pages-suivi-derivation-tsx" */),
  "component---src-pages-suivi-sleeve-tsx": () => import("./../../../src/pages/suivi_sleeve.tsx" /* webpackChunkName: "component---src-pages-suivi-sleeve-tsx" */),
  "component---src-pages-suivi-tsx": () => import("./../../../src/pages/suivi.tsx" /* webpackChunkName: "component---src-pages-suivi-tsx" */),
  "component---src-pages-supplementations-tsx": () => import("./../../../src/pages/supplementations.tsx" /* webpackChunkName: "component---src-pages-supplementations-tsx" */)
}

